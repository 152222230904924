<template>
	<div class="single-company">
		<div class="d-flex justify-content-between flex-wrap align-items-center pt-4 pb-3">
			<div class="h3 mb-0">{{$t("titles.company")}}</div>
			<div class="btn-toolbar mb-2 mb-md-0">
				<small-button class="mr-2" v-if="hasGlobalSuperAdmin && routeEntity.contract !== null" :text="$t('sentences.downloadContract')" @click.native="downloadContract" :loader-active="downloading" />
				<small-button v-if="mayEdit" :text="$t('terms.edit')" @click.native="goTo({name: 'company-edit',params:{id:routeEntity.id}})" />
			</div>
		</div>
		<spinner v-if="isLoading"/>
		<template v-else>
			<form-container>
				<div class="part-label col-12">{{$t("titles.companyDetails")}}</div>
				<field-input :label='$t("labels.company")' id="company" v-model="routeEntity.name" :disabled="true" />
				<field-input :label='$t("labels.vatNumber")' id="cvr" v-model="routeEntity.cvr" :disabled="true"  />
				<field-input :label='$t("labels.billingEmail")' id="billing-email" v-model="routeEntity.billingEmail" :disabled="true"  />
				<field-input :label='$t("labels.attPerson")'  id="att-person" v-model="routeEntity.billingName" :disabled="true"  />
				<field-input :label='$t("labels.billingAddress")'  id="billing-address" v-model="routeEntity.billingAddress" :disabled="true" />
				<field-input :label='$t("labels.postalZip")'  id="postal-zip" classes="col-4 col-md-2" v-model="routeEntity.billingPostal" :disabled="true"  />
				<field-input :label='$t("labels.city")'  id="city" classes="col-8 col-md-4" v-model="routeEntity.billingCity" :disabled="true"  />
				<field-input :label='$t("labels.phone")'  id="Phone" v-model="routeEntity.phone" :disabled="true"  />
				<field-country-select :label='$t("labels.country")'  id="Country" v-model="routeEntity.billingCountry" :disabled="true" />
				<template v-if="hasGlobalSuperAdmin">
					<field-select class="col-12 col-md-6" :label="$t('labels.companyType')" v-model="routeEntity.type" :options="[{value:companyTypes.COMPANY.type,text:$t(companyTypes.COMPANY.text)},{value:companyTypes.PARTNER.type,text:$t(companyTypes.PARTNER.text)}]" :disabled="true"/>
					<field-input :label='$t("labels.allowBooking")' id="allow-booking" :disabled="true" :value='routeEntity.allowBooking ? $t("terms.yes"):$t("terms.no")' />
				</template>
			</form-container>
			<action-list
				entity="User"
				:title="$tc('terms.employee',2)"
				:create="{
					route: 'user-add',
					permissions: [permissions.SCREEN.USER,permissions.USER.CREATE]
				}"
				:showDelete="isGranted([permissions.USER.DELETE],routeEntity['@id'])"
				:columns="[
					{name:'id',header:'#ID'},
					{name:'name',header:$t('listHeaders.name')},
					{name:'email',header:$t('listHeaders.email')},
					{name:'roles',header:$tc('listHeaders.role',2)},
				]"
				:staticParameters="{
					'companies': routeEntity.id,
				}"
			>
				<template v-slot:roles="slotProps">
					{{$t("roles."+slotProps.data.primaryRole)}}
				</template>
			</action-list>
			<action-list
				entity="BookingSegment"
				:title="$tc('listHeaders.upcomingBookings',2)"
				:create="{
					link: createLink
				}"
				:showDelete="isGranted([permissions.BOOKING.DELETE],routeEntity['@id'])"
				:columns="[
					{name:'id',header:'#ID'},
					{name:'date',header:$t('listHeaders.startDate')},
					{name:'startHour',header:$t('listHeaders.fromTo')},
					{name:'pilot',header:$tc('listHeaders.driver',1)},
					{name:'price',header:$t('listHeaders.price')},
					{name:'status',header:$t('listHeaders.status')},
				]"
				:staticParameters="{
					'booking.company': routeEntity.id,
					status_equals: [3,4],
					'order[date]': 'asc',
					'order[startHour]': 'asc',
					explode: 'user:name',
				}"
			>
				<template v-slot:startHour="slotProps">
					{{("0"+slotProps.data.startHour).slice(-2)+" - "+("0"+slotProps.data.endHour).slice(-2)}}
				</template>
				<template v-slot:date="slotProps">
					{{slotProps.data.date | moment("DD-MM-YYYY")}}
				</template>
				<template v-slot:pilot="slotProps">
					<template v-if="slotProps.data.pilot === null">
						{{$t('errors.notFulfilled')}}
					</template>
					<template v-else>
						{{slotProps.data.pilot.name}}
					</template>
				</template>
				<template v-slot:address="slotProps">
					{{slotProps.data.booking.address}}
				</template>
				<template v-slot:status="slotProps">
					{{formatBookingSegmentStatus(slotProps.data.status)}}
				</template>
				<template v-slot:price="slotProps">
					{{slotProps.data.priceFormatted}}
				</template>
			</action-list>
			<action-list
				entity="Machine"
				:title="$tc('terms.machine',2)"
				:columns="[
					{name:'id',header:this.$t('listHeaders.id')},
					{name:'createdAt',header:this.$t('listHeaders.createdAt')},
					{name:'name',header:this.$t('listHeaders.name')},
					{name:'district',header:this.$tc('listHeaders.district',1)},
					{name:'machineStatus',header:this.$tc('listHeaders.status')},
				]"
				:staticParameters="{
					'company': routeEntity.id,
				}"
			>
			</action-list>
		</template>
	</div>
</template>

<script>
	import Spinner from "@/components/loaders/Spinner";
	import isGranted from "@/mixin/isGranted";
	import {mapState} from "vuex";
	import EntitySelect from "@/components/inputs/EntitySelect";
	import FormContainer from "@/components/container/FormContainer";
	import FieldInput from "@/components/inputs/FieldInput";
	import SmallButton from "@/components/buttons/SmallButton";
	import ActionList from '@/components/list/ActionList.vue';
	import DataConversionMethods from '@/mixin/dataConversionMethods.js';
	import permissions from "@/permissions";
	import FieldCountrySelect from "@/components/inputs/FieldCountrySelect";
	import FieldSelect from "@/components/inputs/FieldSelect";
	import companyTypes from "@/companyTypes";

	export default {
		name: "SingleCompany",
		components: {
			FieldSelect,
			FieldCountrySelect, Spinner, EntitySelect, FormContainer, FieldInput, SmallButton, ActionList},
		mixins: [isGranted, DataConversionMethods],
		data(){
			return {
				downloading: false,
				isLoading: true,
				permissions: permissions,
				createLinkRaw: process.env.VUE_APP_CONCRETE_BOOKING_URL,
			}
		},
		computed: {
			...mapState('concrete', {
				companies: 'companies',
				apiKey: 'apiKey'
			}),
			companyTypes(){
				return companyTypes;
			},
			createLink(){
				return this.createLinkRaw+this.$i18n.locale+'?userToken='+this.apiKey
			},
			routeEntity(){
				return (typeof this.$route.params.id !== 'undefined' && typeof this.companies[this.$route.params.id] !== 'undefined') ? this.companies[this.$route.params.id] : false ;
			},
			mayEdit(){
				return this.viewingEntity !== false ? this.isGranted(permissions.COMPANY.UPDATE,this.viewingEntity) : this.isGranted(permissions.COMPANY.UPDATE) ;
			},
		},
		methods: {
			downloadContract(){
				if(this.downloading === false)
				{
					this.downloading = true;
					this.$store.dispatch('concrete/genericEntityRequest',{entity: {'@id': this.routeEntity.contract,'@type':'MediaObject'}})
					.then(response => {
						console.log(response)
						this.downloading = false;
						this.downloadFile(process.env.VUE_APP_CONCRETE_API_URL+response.contentUrl)
					})
					.catch(error => {
						console.log(error);
						this.downloading = false;
						this.fireGenericError()
					})
				}
			},
			async downloadFile(url) {
				let response = await fetch(url);
				let data = await response.blob();
				let metadata = {
					type: 'application/pdf'
				};
				let file = new File([data], "contract.pdf", metadata);

				// Create a link and set the URL using `createObjectURL`
				const link = document.createElement("a");
				link.style.display = "none";
				link.href = URL.createObjectURL(file);
				link.download = file.name;

				// It needs to be added to the DOM so it can be clicked
				document.body.appendChild(link);
				link.click();

				// To make this work on Firefox we need to wait
				// a little while before removing it.
				setTimeout(() => {
					URL.revokeObjectURL(link.href);
					link.parentNode.removeChild(link);
				}, 0);
			},
			goTo(route){
				if(typeof route.params.lang === 'undefined')
					route.params.lang = this.$i18n.locale
				this.$router.push(route);
			},
			fetchCompany(){
				if(this.routeEntity === false)
				{
					this.isLoading = true;
					this.$store.dispatch('concrete/genericEntityRequest',{entity: {'id': this.$route.params.id,'@type':'Company'}})
					.catch(error => {
						console.log(error);
					})
					.then(() => {
						this.isLoading = false;
					},() => {
						this.isLoading = false;
					})
				}
				else
				{
					this.isLoading = false;
				}
			}
		},
		mounted()
		{
			this.fetchCompany();
		},
		watch: {
			$route: {
				deep:true,
				handler: function(){
					this.fetchCompany();
				}
			}
		}
	};
</script>

<style scoped>

</style>
